<template>
<span>

    <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
        <v-container fluid>
            <v-row>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field v-model="contentType.cont_ty_nome" :rules="rules.nomeRules" label="Nome" outlined required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field v-model="contentType.cont_ty_descricao" :rules="rules.descricaoRules" label="Descrição" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-switch color="lime" v-model="contentType.cont_ty_status" label="Status"></v-switch>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn class="btn-salvar" :disabled="!valid" color="success" @click="onSubmit">
                        <v-icon class="mdi mdi-check"></v-icon>
                        {{btnLabel}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../../config/configs'

export default {
    created() {

    },
    computed: {

    },
    props: {
        contentType: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    cont_ty_id: '',
                    cont_ty_nome: '',
                    cont_ty_descricao: '',
                    cont_ty_icone: '',
                    cont_ty_imagem: '',
                    cont_ty_status: false,
                }
            }
        },
        update: {
            require: false,
            type: Boolean,
            default: false
        },
        btnLabel: {
            require: false,
            type: String,
            default: 'Salvar'
        },
        require: true,
    },
    name: "FormContentTypeComponent",

    data() {
        return {
            valid: true,
            rules: {
                nomeRules: [
                    v => !!v || 'Campo Nome é obrigatório',
                    v => (v && v.length <= 200) || 'Nome precisa ter ate 200 Caracteres'
                ],
            },
            base_url: URL_BASE
        }
    },
    methods: {
        onSubmit() {

            if (this.$refs.form.validate()) {
                const action = this.update ? 'updateContentType' : 'storeContentType';
                const msg = this.update ? 'Dados atualizados com sucesso' : 'Cadastro efetuado com sucesso';

                this.$store.dispatch(action, this.contentType)
                    .then(() => {
                        this.$swal({
                            position: "center",
                            icon: "success",
                            title: msg,
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.$router.push({
                            name: 'contentTypes'
                        });
                    })
                    .catch(error => {
                        this.$swal({
                            position: "center",
                            icon: "error",
                            title: 'Erro',
                            text: 'Algo deu errado',
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.errors = error.response.data.errors
                    })
            } else {
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: 'Alguns campos requer sua atenção',
                    text: 'Verifique os campos em vermelho',
                    showConfirmButton: true,
                    timer: 6000
                });
            }

        },
    }
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-salvar {
    margin: 0;
}
</style>
